@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
:root {
  --burnt-orange: #CC5500; 
  --brown: #964B00;
  --ivory: #FFFFF0;
  --olive: #808000;
  --dark-blue: #242F40;
}

h1, h2, h3 {
  font-family: "Nunito", sans-serif;
}

.header {
  padding-top: 30px;
}
.header .container .row {
  align-items: center;
}
.header .logo-wrapper a {
  display: block;
  width: fit-content;
  -moz-width: fit-content;
}
.header .header-links-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.header .header-links-wrapper li a {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-blue);
}
.header .header-links-wrapper li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: var(--burnt-orange);
  transition: all .3s ease-in-out;
}
.header .header-links-wrapper li a:hover::after {
  width: 100%;
}
@media (min-width: 992px) {
  .header .header-links-wrapper {
    gap: 30px;
  }
}
/* short intro */
.short-intro {
  margin-top: 40px;
  height: 550px;
  text-align: center;
}
.short-intro h1 {
  text-transform: uppercase;
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 23px;
}
.short-intro p {
  font-size: 18px;
}
.short-intro .short-intro-img {
  position: relative;
  top: -50px;
  z-index: -1;
}
.short-intro .short-intro-img img {
  max-width: 350px;
  height: auto;
}
@media (min-width: 992px) {
  .short-intro {
    margin-top: 75px;
    height: 800px;
  }
  .short-intro h1 {
    font-size: 38px;
  }
  .short-intro p {
    font-size: 22px;
  }
  .short-intro .short-intro-img {
    top: -100px;
  }
  .short-intro .short-intro-img img {
    width: 700px;
    max-width: fit-content;
    -moz-width: fit-content;
  }
}
/* long intro */
.long-intro {
  width: 100%;
  background-color: var(--dark-blue);
  padding-top: 100px;
  padding-bottom: 100px;
}
.long-intro-copy {
  text-align: center;
}
.long-intro-copy h2 {
  font-weight: 500;
  color: var(--ivory);
  padding-bottom: 15px;
}
.long-intro-copy p {
  color: var(--ivory);
  font-size: 1.15rem;
  line-height: 1.5;
}
.long-intro-copy p strong {
  color: var(--burnt-orange);
}
@media (min-width: 992px) {
  .long-intro-copy p {
    color: var(--ivory);
    font-size: 1.25rem;
  }
}
/* skills */
.skills {
  padding-top: 50px;
  padding-bottom: 100px;
}
.skills-header {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}
.skills-header h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}
.skills-header h2::after {
  content: "";
  display: block;
  position: absolute;
  width: 25%;
  height: 2px;
  background-color: var(--burnt-orange);
  left: 50%;
  transform: translate(-50%, 0%);
}
.skills-cards {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0;
}
.skills-cards .skills-icon {
  display: inline-block;
  background-color: var(--burnt-orange);
  border-radius: 50%;
  padding: .5em;
}
.skills-cards .divider {
  height: 1px;
  background-color: var(--burnt-orange);
}
.skills-card--left, .skills-card--right {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.skills-card--left h3, .skills-card--right h3 {
  font-weight: 700;
  padding-top: 30px;
}
.skills-card--left h5, .skills-card--right h5 {
  font-weight: 500;
  padding-top: 30px;
  padding-bottom: 15px;
}
.skills-languages, .skills-tools {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}
.skills-languages li, .skills-tools li {
  padding: 5px 15px 5px 15px;
  width: 150px;
  background-color: var(--dark-blue);
  border-radius: 5rem;
  color: var(--ivory);
  font-weight: 600;
}
@media (min-width: 992px) {
  .skills {
    padding-top: 100px;
    padding-bottom: 200px;
  }
  .skills-header h2 {
    font-size: 38px;
  }
  .skills-cards {
    flex-direction: row;
    justify-content: center;
  }
  .skills-cards .divider {
    width: 1px;
    height: 50%;
    margin: auto 0;
  }
}
/* projects */
.projects {
  padding-bottom: 100px;
}
.projects .row {
  row-gap: 24px;
}
.projects-header {
  text-align: center;
  padding-bottom: 50px;
}
.projects-header h1 {
  color: var(--dark-blue);
  font-weight: 700;
}
.project-card {
  position: relative;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
}
.project-card .project-card-img {
  display: flex;
  justify-content: center;
  height: 325px;
}
.project-card .project-card-img img {
  object-fit: contain;
  width: 225px;
  height: auto;
}
.project-card-content {
  padding: 20px 20px;
  height: 270px;
}
.project-card-content a {
  display: block;
  position: relative;
  width: fit-content;
  -moz-width: fit-content;
}
.project-card-content .project-card-title, .project-card-content .project-card-title-link {
  font-size: 22px;
  padding-bottom: 20px;
}
.project-card-content .project-card-title-link,
.project-card-content .project-card-title {
  color: gray;
  font-weight: 400;
  transition: all .2s ease;
}
.project-card-content .project-card-title-link:hover,
.project-card-content .project-card-contributions .project-card-contributions-item a:hover {
  color: var(--dark-blue)
}
.project-card-content .project-card-contributions-header {
  font-size: 18px;
  color: var(--dark-blue);
}
.project-card-content .project-card-contributions {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
}
.project-card-content .project-card-contributions .project-card-contributions-item a,
.project-card-content .project-card-contributions .project-card-contributions-item {
  font-size: 16px;
  font-weight: 400;
  color: gray;
  transition: all .2s ease;
}
.project-card-banner {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 8px;
  border-radius: 12px 0 0;
  background-color: var(--ivory);
}
.project-card-banner .project-card-kicker {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0;
  color: var(--dark-blue);
}
.project-card-pills {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  bottom: 20px;
}
.project-card-pills .project-card-pill {
  padding: 5px 15px 5px 15px;
  width: fit-content;
  -moz-width: -moz-fit-content;
  border-radius: 5rem;
  color: var(--ivory);
  font-weight: 600;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .projects .row .col-lg-4 {
    width: 49.99999%;
  }
}
/* footer */
.footer {
  background-color: var(--burnt-orange);
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer .row {
  padding-top: 25px;
  justify-content: center;
}
.footer .footer-copy {
  font-size: 1.6rem;
}
.footer .row li, .footer .footer-copy, .footer .footer-logo {
  text-align: center;
}
.footer .footer-icons {
  width: 100px;
}
@media (min-width: 992px) {
  .footer {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .footer .row {
    padding-top: 50px;
  }
}